import React, { useContext } from 'react';
import { CategoryContext } from '../context/CategoryContext';

const BrandFilter = () => {
  const { setCategory } = useContext(CategoryContext);

  const categories = [
    { id: 'nyka', name: 'Nyka' },
    { id: 'sports', name: 'Sports' },
    { id: 'nike', name: 'Nike' },
    { id: 'lancer', name: 'Lancer' },
    { id: 'mhl', name: 'MHL' },
   
  ];

  return (
    <div className='md:px-4 p-2 overflow-x-auto sm:scrollbar-hide'>
      <div className="flex justify-center md:justify-center pl-4 pr-5 py-4 space-x-2 md:space-x-4">
        {categories.map(category => (
          <button
            key={category.id}
            className="relative bg-white-500 text-black border-2 border-custom-rgba animate-bounce  rounded-full px-2 py-3  whitespace-nowrap"
            onClick={() => setCategory(category.id)}
          >
            <div className=''> {category.name}</div>
           
          </button>
        ))}
      </div>
    </div>
  );
};

export default BrandFilter;
