import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProduct } from '../../Features/Products/ProductsSlice'; // Update the path accordingly
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AddProductForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [images, setImages] = useState(['']);
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [productName, setProductName] = useState('');
  const [features, setFeatures] = useState(['']);

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addProduct({ title, description, price, category, images, color, size, productName, features }));
    setTitle('');
    setDescription('');
    setPrice('');
    setCategory('');
    setImages(['']);
    setColor('');
    setSize('');
    setProductName('');
    setFeatures(['']);
    navigate('/'); // Redirect to the product list page
  };

  const handleImageChange = (index, value) => {
    const newImages = [...images];
    newImages[index] = value;
    setImages(newImages);
  };

  const handleAddImage = () => {
    setImages([...images, '']);
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...features];
    newFeatures[index] = value;
    setFeatures(newFeatures);
  };

  const handleAddFeature = () => {
    setFeatures([...features, '']);
  };

  return (
    <div className='w-full min-h-screen overflow-y-hidden'>
      <form onSubmit={handleSubmit} className="max-w-max mx-auto mt-10 p-4 border-2 border-amber-500 rounded-md bg-white shadow-md">
        <h1 className='text-lg font-bold text-black text-center justify-center'>Add New Product</h1>
        <div className="mb-4">
          <label className="block text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Product Name</label>
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Category</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Price</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Color</label>
          <input
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Size</label>
          <input
            type="text"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Images</label>
          {images.map((image, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={image}
                onChange={(e) => handleImageChange(index, e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1 mr-2"
              />
              <button type="button" onClick={handleAddImage} className="px-4 py-2 bg-green-500 text-white rounded">
                Add
              </button>
            </div>
          ))}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Features</label>
          {features.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={feature}
                onChange={(e) => handleFeatureChange(index, e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1 mr-2"
              />
              <button type="button" onClick={handleAddFeature} className="px-4 py-2 bg-green-500 text-white rounded">
                Add
              </button>
            </div>
          ))}
        </div>
        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Add Product</button>
      </form>
    </div>
  );
};

export default AddProductForm;
