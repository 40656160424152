import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { CategoryContext } from '../context/CategoryContext';
import { addRecentView } from '../Features/recent/recentviewSlice';
import { setProducts } from '../Features/Products/ProductsSlice';

const ProductList = () => {
  // Fetch products from the Redux store, provide default empty array if undefined
  const products = useSelector(state => state.products.items || []);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const location = useLocation();
  const { category: contextCategory } = useContext(CategoryContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!products.length) {
      dispatch(setProducts());
    }
  }, [products, dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryCategory = query.get('category');
    const category = queryCategory || contextCategory || 'all';

    if (category !== 'all') {
      // Ensure that product and product.category are not undefined
      setFilteredProducts(
        products.filter(product =>
          product &&
          product.category?.toLowerCase() === category.toLowerCase() ||
          product.gender?.toLowerCase() === category.toLowerCase()
        )
      );
    } else {
      setFilteredProducts(products);
    }
  }, [location.search, contextCategory, products]);

  const handleProductClick = (productId) => {
    dispatch(addRecentView(productId));
  };

  return (
    <div className='sm:justify-start md:justify-center lg:justify-center md:mx-auto lg:mx-auto'>
      <div className='flex lg:justify-center  justify-start md:justify-start gap-5 items-center'>
        <h3 className='text-lg font-bold'>
          Products for you
          <hr className='w-20 mx-auto h-1 bg-black mt-1'></hr>
        </h3>

      </div>
      <div className='w-3/4 px-4 justify-center mx-auto lg:mx-auto md:mx-auto p-2'>
        <div className="grid grid-cols-2  sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2  ">
          {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <Link
                to={`/product/${product.id}`}
                key={product.id}
                onClick={() => handleProductClick(product.id)}
              >
                <div className="border p-2 max-w-max max-h-min rounded-md bg-white shadow-lg">
                  <div className='justify-center p-4 mx-auto'>
                    <img
                      src={product.image}
                      alt={product.title}
                      className="text-sm h-18 justify-center mx-auto my-auto rounded-sm mb-2"
                    />
                  </div>
                  <div className='text-left'>
                    <h4 className="font-bold text-md">{product.title}</h4>
                    <p className="text-sm">₹{product.price}</p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className='text-center w-full col-span-2 lg:col-span-6'>No products found</p>
          )}
        </div>
      </div>

    </div>
  );
};

export default ProductList;
