import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  address: {
    userName: '',
    userEmail: '',
    userAddress: '',
    userZipcode: '',
    userCity: '',
    userState: ''


  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.items.find(i => i.id === item.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...item, quantity: 1 });
      }
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(item => item.id !== action.payload);
    },
    increaseQuantity: (state, action) => {
      const id = action.payload;
      const item = state.items.find(i => i.id === id);
      if (item) {
        item.quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const id = action.payload;
      const item = state.items.find(i => i.id === id);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    clearCart: (state) => {
      state.items = [];
      state.address = {
        userName: '',
        userEmail: '',
        userAddress: '',
        userZipcode: '',
        userCity: '',
        userState: ''

      };
    }
  }
});

export const {
  addToCart,
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
  updateAddress,
  clearCart
} = cartSlice.actions;

export default cartSlice.reducer;
