// src/components/UserInfo.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAddress } from '../../Features/cart/CartSlice';


const UserInfo = ({ nextStep }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userZipcode, setUserZipcode] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userState, setUserState] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const userDetails = {
      userName,
      userEmail,
      userAddress,
      userZipcode,
      userCity,
      userState,
    };
    dispatch(updateAddress(userDetails));
    nextStep(userDetails);
  };

  return (
    <div className="flex items-start justify-center min-h-screen p-4">
      <form onSubmit={handleSubmit} className="bg-gradient-to-r from-indigo-500 via-pink-500 to-orange-400 p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl bg-transparent font-bold mb-4">Enter Your Information</h1>

        <div className="mb-4 relative">
          <input
            type="text"
            id="userName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userName"
            className="absolute peer-focus:font-bold bg-transparent left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            Name
          </label>
        </div>

        <div className="mb-4 relative">
          <input
            type="email"
            id="userEmail"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userEmail"
            className="absolute peer-focus:font-bold bg-transparent left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            Email
          </label>
        </div>

        <div className="mb-4 relative">
          <input
            type="text"
            id="userAddress"
            value={userAddress}
            onChange={(e) => setUserAddress(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userAddress"
            className="absolute peer-focus:font-bold bg-transparent left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            Address
          </label>
        </div>

        <div className="mb-4 relative">
          <input
            type="text"
            id="userZipcode"
            value={userZipcode}
            onChange={(e) => setUserZipcode(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userZipcode"
            className="absolute peer-focus:font-bold bg-transparent left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            Zip Code
          </label>
        </div>

        <div className="mb-4 relative">
          <input
            type="text"
            id="userCity"
            value={userCity}
            onChange={(e) => setUserCity(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userCity"
            className="absolute peer-focus:font-bold bg-transparent left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            City
          </label>
        </div>

        <div className="mb-4 relative">
          <input
            type="text"
            id="userState"
            value={userState}
            onChange={(e) => setUserState(e.target.value)}
            className="peer bg-gray-100 border-b border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
            placeholder=" "
          />
          <label
            htmlFor="userState"
            className="absolute peer-focus:font-bold bg-transparent  left-3 top-3 text-gray-900 duration-300 transform scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-3 peer-hover:scale-100 peer-hover:translate-y-0 peer-hover:top-3"
          >
            State
          </label>
        </div>

        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Next
        </button>
      </form>
      

    </div>
  );
};

export default UserInfo;
