import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const RecentViews = () => {
  // Access recent view IDs from Redux store
  const recentViewIds = useSelector((state) => state.recentview?.items || []);
  // Access all products from Redux store
  const products = useSelector((state) => state.products?.items || []);

  // Get details of recently viewed products
  const recentViewProducts = products.filter(product => recentViewIds.includes(product.id));

  return (
    <div className='py-5'>
      <div className='flex lg:justify-center justify-start md:justify-start gap-5 items-center'>
        <h3 className='text-lg font-bold'>
          Recent view
          <hr className='w-16 mx-auto h-1 bg-black mt-2'></hr>
        </h3>
      </div>
      <div className="snap-start w-3/4 md:w-3/4 lg:w-3/4 p-2 lg:mx-auto md:mx-auto rounded-md">
        <div className="recent-views-list flex lg:mx-auto mx-3 space-x-2">
          {recentViewProducts.length > 0 ? (
            recentViewProducts.map((product) => (
              <div className="w-100 lg:w-100 md:w-100 max-h-max" key={product.id}>
                <Link to={`/product/${product.id}`}>
                  <div className='recent-view-item bg-gray-100 p-4 w-full h-full shadow-md transition-transform duration-300'>
                    <img
                      src={product.image}
                      alt={product.title}
                      className="h-22 w-32 text-sm justify-center mx-auto my-auto rounded-sm"
                    />
                    <div className="text-left mb-0">
                      <h4 className="text-md font-bold">{product.title}</h4>
                      <p className="text-sm">Rs.{product.price}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p className='text-center w-full col-span-2 lg:col-span-6'>No recent views</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentViews;
