import { configureStore } from '@reduxjs/toolkit';
import productsReducer from '../Features/Products/ProductsSlice';
import authReducer from '../Features/auth/authSlice';
import recentviewSlice from '../Features/recent/recentviewSlice';
import cartSlice from '../Features/cart/CartSlice';
import orderReducer from '../Features/order/orderSlice'

export const store = configureStore({
  reducer: {
    products: productsReducer,
    auth: authReducer,
    recentViews: recentviewSlice,
    cart: cartSlice ,
    order: orderReducer,

  },
});
