import React from 'react';
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';

const AdminDashboard = () => {
  const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const lineData = [
    { name: 'Jan', revenue: 4000, expenses: 2400, amt: 2400 },
    { name: 'Feb', revenue: 3000, expenses: 1398, amt: 2210 },
    { name: 'Mar', revenue: 2000, expenses: 9800, amt: 2290 },
    { name: 'Apr', revenue: 2780, expenses: 3908, amt: 2000 },
    { name: 'May', revenue: 1890, expenses: 4800, amt: 2181 },
    { name: 'Jun', revenue: 2390, expenses: 3800, amt: 2500 },
    { name: 'Jul', revenue: 3490, expenses: 4300, amt: 2100 },
  ];

  const barData = [
    { name: 'Page A', revenue: 4000, expenses: 2400, amt: 2400 },
    { name: 'Page B', revenue: 3000, expenses: 1398, amt: 2210 },
    { name: 'Page C', revenue: 2000, expenses: 9800, amt: 2290 },
    { name: 'Page D', revenue: 2780, expenses: 3908, amt: 2000 },
    { name: 'Page E', revenue: 1890, expenses: 4800, amt: 2181 },
    { name: 'Page F', revenue: 2390, expenses: 3800, amt: 2500 },
    { name: 'Page G', revenue: 3490, expenses: 4300, amt: 2100 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // Calculate total sales, expenses, and profit
  const totalSales = lineData.reduce((acc, { revenue }) => acc + revenue, 0);
  const totalExpenses = barData.reduce((acc, { expenses }) => acc + expenses, 0);
  const totalProfit = totalSales - totalExpenses;

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h2 className="text-4xl font-semibold text-gray-800 mb-6">Dashboard</h2>

      <div className="bg-white rounded-lg mb-3 shadow-lg p-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Financial Overview</h3>
        <div className="flex justify-between items-center">
          <div>
            <p className="text-lg font-semibold text-gray-800">Total Sales</p>
            <p className="text-2xl font-bold text-blue-500">₹{totalSales}</p>
          </div>
          <div>
            <p className="text-lg font-semibold text-gray-800">Total Expenses</p>
            <p className="text-2xl font-bold text-red-500">₹{totalExpenses}</p>
          </div>
        </div>
        <hr className="my-4" />
        <div className="flex justify-between items-center">
          <p className="text-lg font-semibold text-gray-800">Total Profit</p>
          <p className={`text-2xl font-bold ${totalProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>₹{totalProfit}</p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-lg overflow-auto">
          <div className="p-6">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Pie Chart</h3>
            <div className="aspect-w-1 aspect-h-1">
              <PieChart width={340} height={340}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                  outerRadius={65}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-auto">
          <div className="p-6">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Line Chart</h3>
            <LineChart
              width={350}
              height={350}
              data={lineData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="expenses" stroke="#82ca9d" />
            </LineChart>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-auto">
          <div className="p-6">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Bar Chart</h3>
            <BarChart
              width={350}
              height={350}
              data={barData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="revenue" fill="#8884d8" />
              <Bar dataKey="expenses" fill="#82ca9d" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
