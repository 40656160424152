// src/Features/recentViews/recentViewsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const recentViewsSlice = createSlice({
  name: 'recentViews',
  initialState: {
    items: [],
  },
  reducers: {
    addRecentView: (state, action) => {
      // Remove the product if it's already in the recent views to avoid duplicates
      state.items = state.items.filter(item => item.id !== action.payload.id);
      // Add the product to the start of the array
      state.items.unshift(action.payload);
      // Keep only the last 5 items
      if (state.items.length > 5) {
        state.items.pop();
      }
    },
    clearRecentViews: (state) => {
      state.items = [];
    },
  },
});

export const { addRecentView, clearRecentViews } = recentViewsSlice.actions;
export default recentViewsSlice.reducer;
