import { createSlice } from '@reduxjs/toolkit';

// Define the initial state with sample products
const initialState = {
  items: [
    {
      id: "1",
      title: "Women Suit",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
      price: 4000,
      name: "suit",
      gender: "women",
      description: "A beautiful women's suit for all occasions.",
      features: "Elegant, Comfortable, Stylish",
      size: "md"
    },
    {
      id: "2",
      title: "Men Shirt",
      image: [
        {
          id: 1,
          Image1: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        },
        {
          id: 2,
          Image2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlgCvUPiBeVXSg2aqaIzSHe-sEMkyCAKqR4Q&s",
        },
        {
          id: 3,
          Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        },
        {
          id: 4,
          Image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        }
      ],
      price: 2500,
      name: "shirt",
      gender: "men",
      description: "A stylish men's shirt suitable for any occasion.",
      features: "Comfortable, Casual, Trendy",
       size: "md"
    },
    {
      id: "3",
      title: "Kids Dress",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
      price: 1500,
      name: "dress",
      gender: "kids",
      description: "A cute dress for kids.",
      features: "Adorable, Colorful, Playful",
       size: "md"
    },
    {
      id: "4",
      title: "Skin Care",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
      price: 1500,
      name: "Skin care",
      gender: "skin",
      description: "Skin care product.",
      features: "Hydrating, Soothing, Refreshing",
       size: "md"
    },
    {
      id: "5",
      title: "Skin Care",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&sdress.jpg",
      price: 1500,
      name: "Skin care",
      gender: "skin",
      description: "Skin care product.",
      features: "Hydrating, Soothing, Refreshing"
    },
    {
      id: "6",
      title: "Skin Care",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
      price: 1500,
      name: "Skin care",
      gender: "skin",
      description: "Skin care product.",
      features: "Hydrating, Soothing, Refreshing"
    },
    {
      id: "7",
      title: "Skin Care",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
      price: 1500,
      name: "Skin care",
      gender: "skin",
      description: "Skin care product.",
      features: "Hydrating, Soothing, Refreshing"
    },
    // Add more products as needed...
  ],
  filteredItems: [],
};

// Create the products slice
const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
   
    setProducts(state, action) {
      state.items = action.payload;
    },
    addProduct(state, action) {
      state.items.push({
        id: Date.now().toString(), // Simple unique ID generation
        ...action.payload
      });
    },
    filterProducts(state, action) {
      const query = action.payload.toLowerCase();
      state.filteredItems = state.items.filter(item =>
        item.name.toLowerCase().includes(query) ||
        item.title.toLowerCase().includes(query) ||
        item.gender.toLowerCase().includes(query)
      );
    },
    filterProductsByBrand(state, action) {
      const brand = action.payload;
      state.filteredItems = state.items.filter((product) => product.brand === brand);
    },
  },
});

export const { setProducts, addProduct, filterProducts, filterProductsByBrand } = productsSlice.actions;
export default productsSlice.reducer;
