import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { orderId } = useParams();
  const orderDetails = useSelector((state) => state.order.orderDetails);

  if (!orderDetails || orderDetails.orderId !== orderId) {
    return <div>No order details available.</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">Order Details</h1>
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <p><strong>Order ID:</strong> {orderDetails.orderId}</p>
        <p><strong>Product:</strong> {orderDetails.product}</p>
        <p><strong>Quantity:</strong> {orderDetails.quantity}</p>
        <p><strong>Price:</strong> Rs.{orderDetails.price}</p>
        <p><strong>Status:</strong> {orderDetails.status}</p>
      </div>
    
      <Link to="/place-order" className="mt-8 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded">
        Place Order
      </Link>
    </div>
  );
};

export default OrderDetails;
