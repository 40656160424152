import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TrackOrder = () => {
  const { orderId } = useParams();
  const trackingInfo = useSelector((state) => state.order.trackingInfo);
  const [orderStatus, setOrderStatus] = useState(null);

  useEffect(() => {
    // Fetch tracking information based on orderId (for simplicity, using state here)
    // In real application, you would fetch this data from an API
    setOrderStatus(trackingInfo[orderId] || 'No tracking information available');
  }, [orderId, trackingInfo]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">Track Order</h1>
      <div className="bg-white p-8 rounded shadow-md w-96">
        <p>Order ID: {orderId}</p>
        <p>Status: {orderStatus}</p>
      </div>
    </div>
  );
};

export default TrackOrder;
