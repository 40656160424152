// src/components/OrderConfirmation.js
import React from 'react';

const OrderConfirmation = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">Order Confirmed!</h1>
        <p>Your order has been successfully placed.</p>
      </div>
    </div>
  );
};

export default OrderConfirmation;
