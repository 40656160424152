import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../../Features/cart/CartSlice';
import { useNavigate } from 'react-router-dom';

const BuyNow = ({ userInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.items);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  // Example: Accessing environment variables
  const phonePeApiKey = process.env.REACT_APP_PHONEPE_API_KEY;
  const paytmApiKey = process.env.REACT_APP_PAYTM_API_KEY;
  const upiApiKey = process.env.REACT_APP_UPI_API_KEY;

  const handlePlaceOrder = () => {
    if (!selectedPaymentMethod) {
      alert('Please select a payment method.');
      return;
    }

    if (selectedPaymentMethod === 'cod') {
      alert(`Order placed with Cash on Delivery!`);
    } else {
      alert(`Order placed with ${selectedPaymentMethod} payment method!`);
      // Perform API call using the respective API key based on selectedPaymentMethod
      if (selectedPaymentMethod === 'phonepe') {
        // Use phonePeApiKey
        console.log(`Using PhonePe API key: ${phonePeApiKey}`);
      } else if (selectedPaymentMethod === 'paytm') {
        // Use paytmApiKey
        console.log(`Using Paytm API key: ${paytmApiKey}`);
      } else if (selectedPaymentMethod === 'upi') {
        // Use upiApiKey
        console.log(`Using UPI API key: ${upiApiKey}`);
      }
    }

    dispatch(clearCart());
    navigate('/order-confirmation');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">Review and Place Order</h1>
        {cart.length > 0 ? (
          <div className="text-left">
            <ul className="mb-4">
              {cart.map((product, index) => (
                <li key={index} className="mb-2">
                  {product.name} - Rs.{product.price} x {product.quantity}
                </li>
              ))}
            </ul>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Payment Method:
              </label>
              <div>
                <label className="block">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="phonepe"
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-gray-700">PhonePe</span>
                </label>
                <label className="block">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="paytm"
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-gray-700">Paytm</span>
                </label>
                <label className="block">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="upi"
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-gray-700">UPI</span>
                </label>
                <label className="block">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="cod"
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-gray-700">Cash on Delivery</span>
                </label>
              </div>
            </div>
            <button
              onClick={handlePlaceOrder}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Place Order
            </button>
          </div>
        ) : (
          <p>No items in cart.</p>
        )}
      </div>
    </div>
  );
};

export default BuyNow;
