// src/components/Payment/OnlinePayment.js
import React from 'react';

const OnlinePayment = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">Online Payment</h1>
        <p>Complete your payment using PhonePe, Paytm, or UPI.</p>
      </div>
    </div>
  );
};

export default OnlinePayment;
