import React from 'react';

const About = () => {
    return (
        <div className="">
            <div className="container mx-auto bg-white p-8 rounded-lg ">
                <div className='pb-4'>
                <h1 className="text-2xl font-bold  text-center ">About Us</h1>
                <hr className='w-16 mx-auto h-1  bg-black'></hr>

                </div>
              
                <div className="text-lg text-gray-700 space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold mb-2">Our Mission</h2>
                        <p>
                            Our mission is to provide the best products at the best prices with exceptional customer service. We aim to create a seamless shopping experience for all our customers.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold mb-2">Our Story</h2>
                        <p>
                            Founded in 2024, we started as a small business with a big vision. Over the years, we have grown into a trusted name in e-commerce, offering a wide range of products to customers worldwide.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-xl font-semibold mb-2">Our Values</h2>
                        <ul className="list-disc list-inside">
                            <li>Customer First: We prioritize our customers above all else.</li>
                            <li>Quality: We offer products of the highest quality.</li>
                            <li>Integrity: We conduct our business with the utmost honesty.</li>
                            <li>Innovation: We continuously strive to improve and innovate.</li>
                        </ul>
                    </section>
                    <section>
                        <h2 className="text-2xl font-semibold mb-2">Our Team</h2>
                        <p>
                            Our team is composed of dedicated professionals who are passionate about what they do. We work hard to ensure that you have a positive shopping experience.
                        </p>
                    </section>
                   
                </div>
            </div>
        </div>
    );
}

export default About;
