import {React, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/Store';
import { CategoryProvider } from './context/CategoryContext';
import ProductsList from './Components/ProductList';
import ProductDetails from './Components/ProductDetail';
import Navbar from './Components/Navbar';
import SimilarProductDetails from './Components/SimilarProductDetails';
import LogIn from './Pages/LogIn';
import Footer from './Components/Footer';
import Product from './Pages/Product';
import FilterButton from './Components/FilterButtons';
import Banner from './Components/Banner';
import Mainsearch from './Components/Serchbar/Mainsearch';
import About from './Pages/About';
import RecentViews from './Components/RecentView';
import Tranding from './Components/Tranding';
import BrandFilter from './Components/BrandFilter';
import Cart from "./Components/cart/Cart";
import BuyNow from './Components/BuyProduct/BuyNow';
import OrderDetails from './Components/BuyProduct/OrderDetails';
import TrackOrder from './Components/BuyProduct/TrackOrder';
import PlaceOrder from './Components/BuyProduct/PlaceOrder';

import UserInfo from './Components/BuyProduct/UserInfo';
import OrderConfirmation from './Components/BuyProduct/OrderConfirmation';
import OnlinePayment from './Components/payment/OnlinePayment';
import CashOnDelivery from './Components/payment/CashOnDelivery';
import Offer from './Components/Offer';



// Admin Components
import AdminSidebar from './Components/admin/AdminSidebar';
import AdminHeader from './Components/admin/AdminHeader';
import AdminDashboard from './Pages/admin/AdminDashboard';
import AdminProducts from './Pages/admin/AdminProducts';
import AdminOrders from './Pages/admin/AdminOrders';
import AdminUsers from './Pages/admin/AdminUsers';
import AddProductForm from './Components/admin/AddProductform';

const App = () => {

  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState({
    userName: '',
    userEmail: '',
    userAddress: '',
  });

  const nextStep = (data) => {
    setUserInfo(data);
    setStep(step + 1);
  };



  return (
    <Provider store={store}>
      <CategoryProvider>
        <Router>
          <div className="flex">
            <Routes>
              {/* Admin Routes */}
              <Route
                path="/admin/*"
                element={
                  <div className="flex">
                    <AdminSidebar />
                    <div className="flex-1">
                      <AdminHeader />
                      <div className="p-4">
                        <Routes>
                          <Route path="" element={<AdminDashboard />} />
                          <Route path="products" element={<AdminProducts />} />
                          <Route path='Add Product' element={<AddProductForm/>}  />
                          <Route path="orders" element={<AdminOrders />} />
                          <Route path="users" element={<AdminUsers />} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                }
              />

              {/* User Routes */}
              <Route
                path="*"
                element={
                  <div className="flex-1">
                    <Navbar />
                    <div className="p-4">
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <>
                              <Mainsearch />
                              <FilterButton />
                              <Banner />
                              <BrandFilter />
                              <RecentViews />
                              <Tranding />
                              <Offer/>
                              <ProductsList />
                            </>
                          }
                        />
                        <Route path="/product/:productId" element={<ProductDetails />} />
                        <Route path="/similar-products/:productId" element={<SimilarProductDetails />} />
                        <Route path="/login" element={<LogIn />} />
                        <Route path="/products" element={<Product />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/" element={<BuyNow />} />
                        <Route path="/order-details" element={<OrderDetails />} />
                        <Route path="/track-order/:orderId" element={<TrackOrder />} />
                        <Route path="/place-order" element={<PlaceOrder />} />
                        <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/online-payment" element={<OnlinePayment />} />
          <Route path="/cash-on-delivery" element={<CashOnDelivery />} />

          <Route path="/tranding" element={<Tranding />} />
                        <Route
                          path="/"
                          element={
                            step === 1 ? <UserInfo nextStep={nextStep} /> : <BuyNow userInfo={userInfo} />
                          }
                        />
                      </Routes>
                    </div>
                    <Footer />
                  </div>
                }
              />
            </Routes>
          </div>
        </Router>
      </CategoryProvider>
    </Provider>
  );
};

export default App;
