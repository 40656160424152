import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { filterProducts } from '../../Features/Products/ProductsSlice';

const SearchBar = () => {
  const [input, setInput] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSearch = () => {
    dispatch(filterProducts(input));
    navigate(`/products?search=${input}`);
  };

  return (
    <div className=' w-full'>
      <div className='flex items-center p-2 bg-white border-2 rounded-md h-10 w-full border-black'>
        <FaSearch className='text-orange-500 cursor-pointer' />
        <input 
          type='text' 
          placeholder='Search for Items' 
          className='bg-transparent border-none ml-1 outline-none text-md flex-grow'
          value={input}
          onChange={handleChange}
        />
        <button 
          onClick={handleSearch} 
          className='bg-orange-600 px-2 py-1 rounded-md text-white ml-2'
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
