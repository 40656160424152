import React, { useState, useEffect } from 'react';
import logo from '../Images/logo.png';
import { FaShippingFast } from "react-icons/fa";
import { TbPhoneCalling } from "react-icons/tb";
import { PiMoneyLight } from "react-icons/pi";
import ScrollToTopButton from './scrollTotop';
import { PiInstagramLogoDuotone, PiWhatsappLogoFill, PiFacebookLogoFill } from "react-icons/pi";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show button when page is scrolled down
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);


  return (
    <div className='h-80'>

      <div className="w-full  md:76 lg:h-76 max-h-max mt-8 mb-2 bottom-10 bg-gray-300">
        <div className=" relative flex-2 bg-gray-800 md:h-16 lg:16 max-h-max flex  flex-col md:flex-row justify-between px-4 py-2 md:px-24  text-white">
          <div className="bg-transparent my-auto flex items-center space-x-2 mb-2 ">
            <FaShippingFast className="my-auto h-8 w-8 bg-transparent" />
            <div className='bg-transparent'>
              <h3 className="font-bold bg-transparent">FREE SHIPPING & RETURN</h3>
              <p className='bg-transparent'>Free shipping on all India orders</p>
            </div>
          </div>
          <div className="my-auto bg-transparent flex items-center space-x-2 mb-2 ">
            <PiMoneyLight className="my-auto h-8 w-8 bg-transparent" />
            <div className='bg-transparent'>
              <h3 className="font-bold bg-transparent">MONEY GUARANTEE</h3>
              <p className='bg-transparent'>30 days money back guarantee</p>
            </div>
          </div>
          <div className="my-auto bg-transparent flex items-center space-x-2">
            <TbPhoneCalling className="my-auto h-8 w-8 bg-transparent" />
            <div className='bg-transparent'>
              <h3 className="font-bold bg-transparent">ONLINE SUPPORT</h3>
              <p className='bg-transparent'>We support online 24/7 on day</p>
            </div>
          </div>
        </div>
        {/** second section */}
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 text-white md:py-5 md:px-14 sm:px:8 justify-between">

          <div>
            <img className='rounded-sm md:h-24 lg:h-24  ' src={logo} alt='logo' />
          </div>
          <div className='grid grid-flow-col px-2 sm:grid-cols-3 gap-2 py-2  md:py-0 lg:py-0 md:grid-cols-3 lg:grid-cols-3 justify-between'>
            <div className="text-black">
              <h3 className='font-bold'>Connect us</h3>
              <a href="/about" ><p className='flex mx-auto my-auto '>Instagram <PiInstagramLogoDuotone className='h-6 w-6 text-center' /></p> </a>
              <a href="/product"><p className='flex mx-auto my-auto '> Whatsapp <PiWhatsappLogoFill className='text-green-800 h-6 w-6 text-center' /></p></a>
              <a href="/product"><p className='flex mx-auto my-auto '>Facebook <PiFacebookLogoFill className='h-6 w-6 text-center' /></p></a>
              
            </div>
            <div className="text-black">
              <h3 className='font-bold'>Get to know us</h3>
              <a href="/about"><p>About us</p></a>
              <a href="/product"><p>Products</p></a>
              <a href="/"><p>My account</p></a>
            </div>
            <div className="text-black">
              <h3 className='font-bold'>Quick Shop</h3>
              <a href="/"><p>Men Fashion</p></a>
              <a href="/"><p>Women Fashion</p></a>
              <a href="/"><p>New Collection</p></a>
            </div>
            <div className="text-black">
              <h3 className='font-bold'>Customer Services</h3>
              <a href="/"><p>Return Policy</p></a>
              <a href="/"><p>Terms & Conditions</p></a>
            </div>
            <div className='justify-center px-2  items-center w-full mx-auto'>
              <button className='bg-gradient-to-r from-indigo-500 via-pink-500 to-orange-400 hover:bg-orange-300 text-black rounded-md justify-center mx-auto border-2 p-2 border-black'>
                Play Store
              </button>

            </div>

          </div>


        </div>


        <p className='text-Black text-center pb-4'>2024@Bazzarsell, All right reserved</p>
      </div>


      <ScrollToTopButton />



    </div>
  );
};

export default Footer;
