// features/order/orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderDetails: {},
  trackingInfo: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    item : [],
  },
  reducers: {
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setTrackingInfo: (state, action) => {
      state.trackingInfo = action.payload;
    },
  },
});

export const { setOrderDetails, setTrackingInfo } = orderSlice.actions;
export default orderSlice.reducer;
