import React, { useState } from 'react';

const AdminOrders = () => {
  const [orders, setOrders] = useState([
    { id: '001', customer: 'John Doe', amount: '100', status: 'Pending' },
    { id: '002', customer: 'Jane Smith', amount: '150', status: 'Processing' },
    // Add more orders as needed
  ]);

  const handleApprove = (orderId) => {
    // Logic to approve order with orderId
    console.log(`Order ${orderId} approved`);
  };

  const handleReject = (orderId) => {
    // Logic to reject order with orderId
    console.log(`Order ${orderId} rejected`);
  };

  return (
    <div className="w-5/4 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Orders</h2>
      <div className="overflow-x-auto">
        <table className="table-auto w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4">Order ID</th>
              <th className="py-2 px-4">Customer</th>
              <th className="py-2 px-4">Amount</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id} className="border-b border-gray-200">
                <td className="py-2 px-4">{order.id}</td>
                <td className="py-2 px-4">{order.customer}</td>
                <td className="py-2 px-4">Rs.{order.amount}</td>
                <td className="py-2 px-4">{order.status}</td>
                <td className="py-2 px-4">
                  <button
                    className="bg-green-500 text-white px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-green-400"
                    onClick={() => handleApprove(order.id)}
                  >
                    Approve
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2 focus:outline-none focus:ring-2 focus:ring-red-400"
                    onClick={() => handleReject(order.id)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminOrders;
