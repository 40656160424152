import React from 'react'
import LoginSign from '../Components/LoginSign'

const LogIn = () => {
  return (
    <div id='login'>
      <LoginSign />
    </div>
  )
}

export default LogIn;
