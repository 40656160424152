import React from 'react';


const TradingProducts = [
    {
        id: 1,
        title: "Women",
        category: "Women",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUw-8HSwGJTpe4raCJFsx5WVpfw19KKExYdQ&s",
        name: "women suit",
        offer: '20%',
    },
    {
        id: 2,
        title: "men",
        category: "men",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        name: "women suit",
        offer: '20%',
    },
    {
        id: 3,
        title: "kids",
        category: "kids",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        name: "kids",

        offer: '20%',
    },
    {
        id: 4,
        title: "Women",
        category: "Women",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        name: "women suit",

        offer: '20%',
    },
    {
        id: 5,
        title: "men",
        category: "men",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvlVy-fMwTU8OoGd7A0v90zyhOO31tIxe5RQ&s",
        name: "women suit",
        price: 300,
        offerprice: 200,
        offer: '20%',
    }

];




const Tranding = () => {
    return (
        <div className='sm:justify-start md:justify-center lg:justify-center md:mx-auto lg:mx-auto'>
            <div className='flex lg:justify-center  justify-start md:justify-start gap-5 items-center'>
                <h3 className='text-lg font-bold'>
                    #Trand Store
                    <hr className='w-16 mx-auto h-1 bg-black mt-2'></hr>
                </h3>

            </div>
            

                <div className='scrollbar-hide overfow-x-auto horizontal-hidde w-3/4 px-4 justify-center mx-auto lg:mx-auto md:mx-auto p-2'>
                    <div className='grid grid-cols-2  sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 '>
                        {TradingProducts.map((tranding) => (
                            <div key={tranding.id} className=' bg-gradient-to-t from-orange-500 via-pink-500 to-orange-300 rounded-tr-[15%] rounded-tl-[15%] rounded-bl-[25%] overflow-hidden rounded-br-sm border-amber-500 border-2   '>
                                <div className='bg-transparent hover:cursor-zoom-in relative '>
                                    <img className='rounded-md h-32 w-28  mx-auto' src={tranding.image} alt={tranding.name} />
                                    <div className='text-center bg-gradient-to-t from-pink-400 via-orange-400 to-red-400 relative'>
                                        <h1 className='font-bold text-md'>{tranding.title}</h1>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            
        </div>
    );
}

export default Tranding;
