import React, { useState } from 'react';
import SearchResult from './SearchResult';
import SearchBar from './SearchBar';

const Mainsearch = () => {
    const [result, setResult] = useState({});
    return (
        <div className='flex flex-col items-center w-full p-4'>
            <div className='w-full max-w-lg'>
                <SearchBar setResult={setResult} />
                <SearchResult result={result} />
            </div>
        </div>
    );
}

export default Mainsearch;
