import React from 'react';

const AdminProducts = () => {
  const products = [
    { id: 1, name: 'Product 1', price: '10', stock: 100 },
    { id: 2, name: 'Product 2', price: '20', stock: 50 },
    // Add more products as needed
  ];

  const handleEdit = (productId) => {
    // Logic to handle edit action
    console.log(`Edit product with ID ${productId}`);
  };

  const handleDelete = (productId) => {
    // Logic to handle delete action
    console.log(`Delete product with ID ${productId}`);
  };

  return (
    <div className="container  p-4">
      <h2 className="text-2xl font-bold mb-4">Products</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2">Product</th>
              <th className="py-2">Price</th>
              <th className="py-2">Stock</th>
              <th className="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id} className="border-b border-gray-200">
                <td className="px-4 py-2">{product.name}</td>
                <td className="px-4 py-2">Rs.{product.price}</td>
                <td className="px-4 py-2">{product.stock}</td>
                <td className="px-4 py-2">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                    onClick={() => handleEdit(product.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2 hover:bg-red-600"
                    onClick={() => handleDelete(product.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminProducts;
