import React from 'react';
import { Link } from 'react-router-dom';

const AdminSidebar = () => {
  return (
    <div className='bg-transparent'>
    <aside className="w-64 bg-gray-900 text-white min-h-screen">
      <nav className="p-4 bg-transparent ">
        <ul className='bg-transparent'>
          <li className="mb-4 bg-transparent ">
            <Link to="/admin" className="text-lg bg-transparent  font-bold">Dashboard</Link>
          </li>
          <li className="mb-4 bg-transparent ">
            <Link to="/admin/products" className="text-lg font-bold bg-transparent ">Products</Link>
          </li>
          <li className="mb-4 bg-transparent ">
            <Link to="/admin/Add Product" className="text-lg bg-transparent  font-bold">Add Products</Link>
          </li>
          <li className="mb-4 bg-transparent ">
            <Link to="/admin/orders" className="text-lg bg-transparent font-bold">Orders</Link>
          </li>
          <li className="mb-4 bg-transparent ">
            <Link to="/admin/users" className="text-lg bg-transparent font-bold">Users</Link>
          </li>
        </ul>
      </nav>
    </aside>
    </div>
  );
};

export default AdminSidebar;
