// src/components/CartItem.js
import React from 'react';

const CartItem = ({ item, onIncrease, onDecrease, onRemove }) => {
  return (
    <div className='w-full justify-center grid grid-cols-1'>
      <div className="w-full  grid grid-flow-col border space-x-3 gap-4 p-2 pb-2  rounded-md bg-white shadow-lg">
        <div className='justify-center px-2 mx-auto'>
          <img src={item.image} alt={item.title} className="text-sm  justify-center mx-auto my-auto rounded-sm mb-2" />
        </div>

        <div>

          <div className='grid grid-cols-1 gap-2'>
            <h4 className="font-bold  text-md">{item.name}</h4>
            <p>price:{item.price}</p>
            <p>Description:{item.description}</p>
            <p>Features: {item.features}</p>
            <p>Size:{item.size}</p>

          </div>
          <div className="w-full  justify-left  bottom-0">
            <div className='grid grid-flow-col'>
              <p className="text-sm ">Quantity:  {item.quantity}</p>
              <div className='-space-x-2'>
              <button className="bg-gray-200 px-2 py-1 h-10 w-10 rounded" onClick={() => onDecrease(item.id)}>-</button>
              <button className="bg-gray-200 px-2 py-1 h-10 w-10 rounded" onClick={() => onIncrease(item.id)}>+</button>
              
              </div>
              
            <div>
              <button className="bg-red-500 text-white h-8 w-18  px-2 py-1 rounded" onClick={() => onRemove(item.id)}>Remove</button>
            </div>
            </div>


          </div>


        </div>


      </div>





    </div>

  );
};

export default CartItem;
