import React from 'react';
import { useNavigate } from 'react-router-dom';

const SimilarProducts = ({ similarProducts }) => {
  const navigate = useNavigate ();

  return (
    <div className="-mt-20 md:-mt-5 lg:-mt-5  w-3/4 mx-auto mb-5">
      <h2 className="text-2xl font-bold mb-4">Similar Products</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-6 gap-4">
        {similarProducts.map((similarProduct) => (
          <div 
            key={similarProduct.id} 
            className="bg-white p-4 rounded-lg  shadow-sm shadow-orange-500  cursor-pointer" 
            onClick={() => navigate(`/similar-products/${similarProduct.id}`)}
          >
            <div className='justify-center text-sm'> 
            <img src={similarProduct.image} alt={similarProduct.title} className="w-full  mb-4" />
              </div>
           
            <h3 className="text-md font-semibold">{similarProduct.title}</h3>
            <p className="text-sm">Rs.{similarProduct.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarProducts;
