import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartItem from './CartItem';
import { removeFromCart, increaseQuantity, decreaseQuantity } from '../../Features/cart/CartSlice'; // Adjust the path accordingly

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const cartItems = useSelector(state => state.cart.items);

  const handleIncrease = (id) => {
    dispatch(increaseQuantity(id));
  };

  const handleDecrease = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const handleRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const getTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleBuyNow = () => {
    navigate('/place-order'); // Navigate to PlaceOrder component
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 mb-10 px-4">
      <h1 className="text-3xl font-bold mb-6">Shopping Cart</h1>
      {cartItems.length > 0 ? (
        <div className="bg-white p-4 gap-4 mx-auto shadow-md rounded-lg overflow-hidden">
          <div className="divide-y divide-gray-200">
            {cartItems.map(item => (
              <CartItem
                key={item.id}
                item={item}
                onIncrease={() => handleIncrease(item.id)}
                onDecrease={() => handleDecrease(item.id)}
                onRemove={() => handleRemove(item.id)}
               
              />
            ))}
          </div>
          <div className="flex mt-4 justify-between items-center p-4 border-t">
            <h2 className="text-xl font-semibold">Total: Rs. {getTotalAmount()}</h2>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-md transition duration-300"
              onClick={handleBuyNow} 
            >
              Buy Now
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center mt-4 text-gray-600">Your cart is empty</p>
      )}
    </div>
  );
};

export default Cart;
