import React from 'react';

const AdminUsers = () => {
  return (
    <div className='w-3/4'>
      <h2 className="text-2xl font-bold mb-4">Users</h2>
      <table className="w-full  bg-white">
        <thead>
          <tr>
            <th className="py-2">User ID</th>
            <th className="py-2">Name</th>
            <th className="py-2">Email</th>
            <th className="py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">001</td>
            <td className="border px-4 py-2">Jane Doe</td>
            <td className="border px-4 py-2">jane@example.com</td>
            <td className="border px-4 py-2">
              <button className="bg-blue-500 text-white px-2 py-1 rounded">Edit</button>
              <button className="bg-red-500 text-white px-2 py-1 rounded ml-2">Delete</button>
            </td>
          </tr>
          {/* More rows */}
        </tbody>
      </table>
    </div>
  );
};

export default AdminUsers;
