import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi'; // Import arrow icon from react-icons

const SimilarProductDetails = () => {
    const { productId } = useParams();
    const navigate = useNavigate();

    const similarProduct = useSelector((state) =>
        state.products.items.find(product => product.id === productId)
    );

    if (!similarProduct) {
        return <div>Product not found</div>;
    }

    return (
        <div className="w-full h-screen bg-gray-100 fixed top-0 left-0 z-50 overflow-y-auto">
            <div>
                <button onClick={() => navigate(-1)}>
                    <FiArrowLeft size={25} className="m-10 text-black" /> {/* Arrow icon */}
                </button>
            </div>

            <div className="grid grid-rows-2 mx-auto">
                <div className="container  w-2/3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4  justify-center  items-center mx-auto rounded-md">
                    <div>
                        <img src={similarProduct.image} alt={similarProduct.title} className="w-full text-pretty text-sm h-auto" />
                    </div>
                    <div>
                        <h1 className="text-xl font-bold">{similarProduct.title}</h1>
                        <p className="text-sm">Description:<br />{similarProduct.description}</p>
                        <p className="font-semibold">Rs.{similarProduct.price}</p>
                        <p className="text-sm">Features: {similarProduct.features}</p>
                    </div>
                </div>
                <br />
                <div className="relative w-3/4 flex justify-between   my-5 mx-auto  ">
                    <button className="bg-black text-white h-10 px-3 py-1 rounded-lg border-2 border-black hover:border-orange-500 transition duration-300 mr-2">Add to Cart</button>
                    <button className="bg-black text-white h-10 px-3 py-1 rounded-lg border-2 border-black hover:border-orange-500 transition duration-300">Buy Now</button>
                </div>

            </div>
            <hr className='horiZonatal-line w-3/4 mx-auto  border-t-1 bg-orange-300 h-1 ' />
        </div>

    );
};

export default SimilarProductDetails;
