import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../Features/auth/authSlice';
import { FaRegUser, FaHome, FaBoxOpen } from "react-icons/fa";
import { MdAddShoppingCart } from "react-icons/md";
import { HiMenu, HiX } from 'react-icons/hi';
import { HiMiniShoppingBag } from 'react-icons/hi2';
import logo from '../Images/logo.png';

const Navbar = () => {
  const user = useSelector((state) => state.auth.user);
  const cartItems = useSelector((state) => state.cart.items || []);
  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white border-b sticky top-0 border-gray-200 z-10">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/">
              <img className="h-12 md:h-16 w-auto" src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="flex items-center justify-end space-x-4">
            <div className="hidden sm:flex space-x-4">
              <Link to="/" className="text-black hover:text-orange-600 px-3 py-2 rounded-md text-md font-bold">Home</Link>
              <Link to="/products" className="text-black hover:text-orange-600 px-3 py-2 rounded-md text-md font-bold">Products</Link>
              <Link to="/about" className="text-black hover:text-orange-600 px-3 py-2 rounded-md text-md font-bold">About</Link>
            </div>
            <div className="hidden sm:flex items-center space-x-4">
              <Link to="/cart" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none relative">
                <MdAddShoppingCart className="h-6 w-6" />
                {cartItemCount > 0 && (
                  <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                    {cartItemCount}
                  </span>
                )}
              </Link>
              <Link to="/orders" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
                <HiMiniShoppingBag className="h-6 w-6" />
              </Link>
              {user ? (
                <div className="relative" ref={dropdownRef}>
                  <button onClick={toggleDropdown} className="flex items-center text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
                    <FaRegUser className="h-6 w-6" />
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                      <span className="block px-4 py-2 text-sm text-gray-700">{user.name}</span>
                      <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
                    </div>
                  )}
                </div>
              ) : (
                <Link to="/login" className="text-black hover:text-orange-600 px-3 py-2 rounded-md text-md font-bold">
                  LogIn
                </Link>
              )}
            </div>
            <div className="sm:hidden flex items-center">
              <button onClick={toggleMobileMenu} className="p-2 rounded-md text-gray-400 hover:text-black focus:outline-none">
                {isMobileMenuOpen ? <HiX className="h-6 w-6" /> : <HiMenu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="sm:hidden mt-2">
            <div className="flex flex-col items-start space-y-1 max-h-60 overflow-y-auto">
              <Link to="/" onClick={handleLinkClick} className="text-black hover:text-orange-600 px-4 py-2 rounded-md">Home</Link>
              <Link to="/products" onClick={handleLinkClick} className="text-black hover:text-orange-600 px-4 py-2 rounded-md">Products</Link>
              <Link to="/about" onClick={handleLinkClick} className="text-black hover:text-orange-600 px-4 py-2 rounded-md">About</Link>
              {user ? (
                <>
                  <span className="text-gray-700 px-4 py-2">{user.name}</span>
                  <button onClick={() => { handleLinkClick(); handleLogout(); }} className="text-gray-700 hover:bg-gray-100 w-full text-left px-4 py-2">Logout</button>
                </>
              ) : (
                <Link to="/login" onClick={handleLinkClick} className="text-black hover:text-orange-600 px-4 py-2 rounded-md">LogIn</Link>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Bottom Navigation for Mobile */}
      <div className="lg:hidden md:hidden justify-around h-screen-bottom fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-10">
        <div className="flex py-2">
          <Link to="/" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
            <FaHome className="h-6 w-6" />
          </Link>
          <Link to="/products" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
            <FaBoxOpen className="h-6 w-6" />
          </Link>
          <Link to="/cart" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none relative">
            <MdAddShoppingCart className="h-6 w-6" />
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                {cartItemCount}
              </span>
            )}
          </Link>
          <Link to="/orders" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
            <HiMiniShoppingBag className="h-6 w-6" />
          </Link>
          {user ? (
            <button onClick={toggleDropdown} className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
              <FaRegUser className="h-6 w-6" />
            </button>
          ) : (
            <Link to="/login" className="text-black hover:text-orange-600 px-3 py-2 rounded-md focus:outline-none">
              <FaRegUser className="h-6 w-6" />
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
