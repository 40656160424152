import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../../Features/cart/CartSlice';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcPaypal, faCcAmazonPay, faGoogleWallet } from '@fortawesome/free-brands-svg-icons';

const PlaceOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.items);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const totalAmount = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  const handlePlaceOrder = () => {
    alert('Order placed successfully!');
    dispatch(clearCart());
    navigate('/order-confirmation');
  };

  const initiateRazorpayPayment = async () => {
    try {
      const response = await axios.post('http://localhost:5000/create-order', {
        amount: totalAmount,
        userDetails: userDetails,
        paymentMethod: 'razorpay'
      });
      const { orderId } = response.data;

      const options = {
        key: 'YOUR_RAZORPAY_KEY_ID',
        amount: totalAmount * 100,
        currency: "INR",
        name: "Your Company Name",
        description: "Test Transaction",
        order_id: orderId,
        handler: function (response) {
          alert(`Payment ID: ${response.razorpay_payment_id}`);
          handlePlaceOrder();
        },
        prefill: {
          name: userDetails.userName,
          email: userDetails.userEmail,
          contact: "9999999999",
        },
        notes: {
          address: userDetails.userAddress,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error initiating Razorpay payment:', error);
    }
  };

  const initiatePaytmPayment = async () => {
    try {
      const response = await axios.post('http://localhost:5000/create-order', {
        amount: totalAmount,
        userDetails: userDetails,
        paymentMethod: 'paytm'
      });
      const { params, checksum } = response.data;

      const paytmForm = document.createElement('form');
      paytmForm.action = 'https://securegw-stage.paytm.in/order/process';
      paytmForm.method = 'POST';
      Object.keys(params).forEach(key => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = params[key];
        paytmForm.appendChild(input);
      });
      const checksumInput = document.createElement('input');
      checksumInput.type = 'hidden';
      checksumInput.name = 'CHECKSUMHASH';
      checksumInput.value = checksum;
      paytmForm.appendChild(checksumInput);
      document.body.appendChild(paytmForm);
      paytmForm.submit();
    } catch (error) {
      console.error('Error initiating Paytm payment:', error);
    }
  };

  const initiatePhonePePayment = async () => {
    try {
      const response = await axios.post('http://localhost:5000/create-order', {
        amount: totalAmount,
        userDetails: userDetails,
        paymentMethod: 'phonepe'
      });
      const { phonePeData, phonePeToken } = response.data;

      const phonePeForm = document.createElement('form');
      phonePeForm.action = 'https://mercury-uat.phonepe.com/v3/transaction';
      phonePeForm.method = 'POST';
      Object.keys(phonePeData).forEach(key => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = phonePeData[key];
        phonePeForm.appendChild(input);
      });
      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'X-VERIFY';
      tokenInput.value = phonePeToken;
      phonePeForm.appendChild(tokenInput);
      document.body.appendChild(phonePeForm);
      phonePeForm.submit();
    } catch (error) {
      console.error('Error initiating PhonePe payment:', error);
    }
  };

  const nextStep = (details) => {
    setUserDetails(details);
  };

  const handlePaymentProceed = () => {
    if (selectedPaymentMethod === 'razorpay') {
      initiateRazorpayPayment();
    } else if (selectedPaymentMethod === 'paytm') {
      initiatePaytmPayment();
    } else if (selectedPaymentMethod === 'phonepe') {
      initiatePhonePePayment();
    } else if (selectedPaymentMethod === 'cod') {
      alert('Order placed with Cash on Delivery!');
      handlePlaceOrder();
    } else {
      alert('Please select a payment method.');
    }
  };

  if (!userDetails) {
    return <UserInfo nextStep={nextStep} />;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg text-center">
        <h1 className="text-3xl font-bold mb-6 bg-transparent text-gray-800">Review Your Order</h1>
        <ul className="mb-4 text-left">
          {cart.map((product, index) => (
            <li key={index} className="">
              <div className='shadow-md  grid grid-cols-2 p-4 shadow-black '>
              <div>
                <img className='h-32 w-28' src={product.image} alt={product.name} />
                
              </div>
              <div className=''>
              <h2 className="font-semibold"> {product.name}</h2>
              <p>Price: Rs.{product.price}</p>
              <p>Quantity: {product.quantity}</p>
              <p>Description: {product.description}</p>

              </div>

              </div>
             
             
            </li>
          ))}
        </ul>
        <div className="mb-4">
          <h2 className="text-xl font-bold">Total Amount: Rs.{totalAmount}</h2>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Payment Method:</label>
          <div className="grid grid-cols-3 gap-2">
            <div
              className={`p-1 border rounded cursor-pointer ${selectedPaymentMethod === 'razorpay' ? 'border-blue-500' : ''}`}
              onClick={() => setSelectedPaymentMethod('razorpay')}
            >
              <FontAwesomeIcon icon={faGoogleWallet} size="2x" className="mx-auto mb-2" />
              <span>Razorpay</span>
            </div>
            <div
              className={`p-1 border rounded cursor-pointer ${selectedPaymentMethod === 'paytm' ? 'border-blue-500' : ''}`}
              onClick={() => setSelectedPaymentMethod('paytm')}
            >
              <FontAwesomeIcon icon={faCcPaypal} size="2x" className="mx-auto mb-2" />
              <span>Paytm</span>
            </div>
            <div
              className={`p-1 border rounded cursor-pointer ${selectedPaymentMethod === 'phonepe' ? 'border-blue-500' : ''}`}
              onClick={() => setSelectedPaymentMethod('phonepe')}
            >
              <FontAwesomeIcon icon={faCcAmazonPay} size="2x" className="mx-auto mb-2" />
              <span>PhonePe</span>
            </div>
            <div
              className={`p-1 border rounded cursor-pointer ${selectedPaymentMethod === 'cod' ? 'border-blue-500' : ''}`}
              onClick={() => setSelectedPaymentMethod('cod')}
            >
              <span>Cash on Delivery</span>
            </div>
          </div>
        </div>
        <button
          onClick={handlePaymentProceed}
          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
        >
          Proceed to Payment
        </button>
      </div>
    </div>
  );
};

export default PlaceOrder;
