import React from 'react';

const AdminHeader = () => {
  return (
    <header className="bg-gray-800 w-auto gap-96 text-white p-4 flex justify-between items-center ">
      <h1 className="text-xl font-bold bg-transparent">Admin Dashboard</h1>
      <div className='justify-end bg-transparent'>
        <span className="mr-4 bg-transparent">Welcome, Admin</span>
        <button className="bg-red-500 px-4 py-2 rounded ">Logout</button>
      </div>
    </header>
  );
};

export default AdminHeader;
