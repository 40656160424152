import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CategoryContext } from '../context/CategoryContext';

// Import images
import womenImage from '../Images/women.png';
import menImage from '../Images/man.jpg';
import kidsImage from '../Images/kids.png';
import beautyImage from '../Images/beauty.png';
import footwearImage from '../Images/shoe.jpg';
import homeDecorImage from '../Images/home.png';

const FilterButton = () => {
  const { setCategory } = useContext(CategoryContext);
  const navigate = useNavigate();

  const categories = [
    { id: 'all', imageurl: '', name: 'All' },
    { id: 'women', imageurl: womenImage, name: 'Women' },
    { id: 'men', imageurl: menImage, name: 'Men' },
    { id: 'kids', imageurl: kidsImage, name: 'Kids' },
    { id: 'beauty', imageurl: beautyImage, name: 'Beauty' },
    { id: 'footwear', imageurl: footwearImage, name: 'Footwear' },
    { id: 'home-decor', imageurl: homeDecorImage, name: 'Home Decor' },
  ];

  const handleFilterClick = (category) => {
    setCategory(category.id);
    navigate(`/products?category=${category.id}`);
  };

  return (
    <div className='scrollbar-hide md:px-4 overflow-x-auto'>
      <div className="flex justify-start md:justify-center pl-4 pr-5 py-4 space-x-2 md:space-x-4">
        {categories.map(category => (
          <button
            key={category.id}
            className="bg-white-500 text-black border-2 border-black hover:border-orange-500 rounded-full px-2 py-1 whitespace-nowrap flex items-center"
            onClick={() => handleFilterClick(category)}
          >
            <div className="flex items-center">
              <div className="w-8 h-8 mr-2 rounded-full overflow-hidden">
                {category.imageurl ? (
                  <img 
                    src={category.imageurl} 
                    alt={category.name} 
                    className="w-full h-full object-cover" 
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center text-xs text-gray-500">
                    No Image
                  </div>
                )}
              </div>
              <span>{category.name}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterButton;
