import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginError } from '../Features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../Components/Login'; // Import LoginForm component
import RegistrationForm from '../Components/Registration';

const LoginSign = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Your login logic here (e.g., call to your authentication API)
    // Dispatch loginSuccess or loginError based on the API response
    dispatch(loginSuccess({ email, name })); // This is just an example
    navigate('/'); // Navigate to home page after login
  };

  return (
    <div className=" ">
      <div className="flex items-center justify-center sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {isLogin ? 'Sign in to your account' : 'Create an account'}
            </h2>
          </div>
          {isLogin ? (
            <LoginForm
              handleLogin={handleLogin}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
            />
          ) : (
            <RegistrationForm />
          )}
          <div className="text-sm text-center ">
            <button
              onClick={toggleForm}
              className="font-medium text-orange-600 hover:text-orange-500 focus:outline-none"
            >
              {isLogin ? 'Don\'t have an account? Sign up here' : 'Already have an account? Sign in here'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSign;
