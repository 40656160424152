import React, { useState, useEffect } from 'react';
import { useDrag } from '@use-gesture/react';
import '../index.css'; // Ensure this is the correct path to your Tailwind CSS file

const images = [
  'https://img.freepik.com/premium-vector/gradient-black-friday-horizontal-sale-banner-template_23-2149815371.jpg?w=1060',
  'https://img.freepik.com/premium-vector/flat-black-friday-sale-background_173356-1604.jpg?w=1060',
  'https://img.freepik.com/premium-vector/halloween-sale-flyer-cute-owl-sorcery-hat-pumpkin-vector-illustration-banner-sale_372860-294.jpg?w=1380',
  'https://img.freepik.com/premium-vector/flat-black-friday-sale-background_173356-1604.jpg?w=1060',
  'https://img.freepik.com/premium-vector/halloween-sale-flyer-cute-owl-sorcery-hat-pumpkin-vector-illustration-banner-sale_372860-294.jpg?w=1380'
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!isDragging) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isDragging]);

  const bind = useDrag(({ down, movement: [mx] }) => {
    setIsDragging(down);
    setOffset(down ? mx : 0);

    if (!down) {
      if (mx < -50) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      } else if (mx > 50) {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      }
    }
  });

  const duplicatedImages = [...images, ...images]; // Repeat images for infinite loop

  return (
    <div className="relative  h-64 w-11/12 md:w-11/12 lg:w-11/12 md:h-80  mx-auto shadow-lg border-b-4 border-t-4 border-orange-500 rounded-lg overflow-hidden">
      <div
        {...bind()}
        className="absolute top-0 left-0 w-full h-full flex transition-transform duration-500"
        style={{ transform: `translateX(calc(-${currentIndex * 100}% + ${offset}px))` }}
      >
        {duplicatedImages.map((image, index) => (
          <div
            key={index}
            className="w-full h-full flex-shrink-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
