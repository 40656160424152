import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import SimilarProducts from './SimilarProducts';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { addToCart } from '../Features/cart/CartSlice'; // Ensure the correct path
import '../App.css';  // Your global CSS file


const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const product = useSelector((state) =>
    state.products.items.find(product => product.id === productId)
  );

  const similarProducts = useSelector((state) =>
    state.products.items.filter(item => item.category === product.category && item.id !== productId)
  );

  useEffect(() => {
    // Logic to fetch product details can be added here if product is not found
  }, [productId]);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleAddToCart = () => {
    dispatch(addToCart({
      id: product.id,
      name: product.title,
      image: product.image,
      price: product.price,
      description: product.description,
      features:product.features,
      color: product.color || 'N/A' // Assuming 'color' is a property in your product object
    }));
    navigate('/cart');
  };

  const handleBuyNow = () => {
    dispatch(addToCart({
      id: product.id,
      name: product.title,
      image: product.image,
      price: product.price,
      description: product.description,
      features:product.features,
      color: product.color, 
      size: product.size || 'N/A' // Assuming 'color' is a property in your product object
    }));
    navigate('/place-order'); // Navigate to the PlaceOrder page
  };

  return (
    <div className="w-full bg-gray-100 fixed top-0 left-0 z-50 overflow-y-auto h-full">
      <div>
        <button onClick={() => navigate(-1)}>
          <FiArrowLeft size={25} className="m-10 text-black" /> {/* Arrow icon */}
        </button>
      </div>
      <div className="grid grid-rows-2 mx-auto">
        <div className="container justify-center hover:rounded-tl-[35%] hover:rounded-br-[36%] shadow-orange-400 p-12 shadow-sm w-2/3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 items-center mx-auto rounded-md">
          <div className="flex justify-center items-center">
            <img src={product.image} alt={product.title} className="rounded-sm cursor-zoom-in text-pretty text-sm" />
          </div>

          <div className="text-right hover:rounded-md p-8 mx-auto w-full h-full hover:shadow-md sm:mt-0 hover:rounded-br-[20%]">
            <h1 className="text-xl font-bold mb-2 text-left">{product.title}</h1>
            <p className="font-semibold mb-2 text-left">₹{product.price}</p>
            <p className="text-sm mb-2 text-left">Description:<br />{product.description}</p>
            <p className="text-sm mb-4 text-left">Features: {product.features}</p>
            <p className="text-sm mb-4 text-left">Size: {product.size}</p>
          </div>
        </div>
        <div className="relative w-3/4 flex justify-between my-5 mx-auto">
          <button 
            className="bg-white  flex text-black h-10 px-3 py-1 rounded-lg border-2 border-black hover:border-orange-500 transition duration-300 mr-2"
            onClick={handleAddToCart}
          >
            <ShoppingCartIcon className='h-5 w-5 my-auto' />
            Add To Cart
          </button>
          
          <button onClick={handleBuyNow} className="buy flex items-center justify-center  h-12 px-6 py-2"> </button>

          
          
        </div>
        <hr className='horizontal-line w-3/4 mx-auto md:-mt-28 -mt-52 border-t-1 bg-orange-300 h-2' />
        <br />
        <SimilarProducts similarProducts={similarProducts} />
      </div>
    </div>
  );
};

export default ProductDetails;
