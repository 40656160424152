import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Offer = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = [
    'https://img.freepik.com/premium-vector/gradient-black-friday-horizontal-sale-banner-template_23-2149815371.jpg?w=1060',
    'https://img.freepik.com/premium-vector/flat-black-friday-sale-background_173356-1604.jpg?w=1060',
    'https://img.freepik.com/premium-vector/halloween-sale-flyer-cute-owl-sorcery-hat-pumpkin-vector-illustration-banner-sale_372860-294.jpg?w=1380',
    'https://img.freepik.com/premium-vector/flat-black-friday-sale-background_173356-1604.jpg?w=1060',
    'https://img.freepik.com/premium-vector/halloween-sale-flyer-cute-owl-sorcery-hat-pumpkin-vector-illustration-banner-sale_372860-294.jpg?w=1380'
  ];

  // Function to go to the next slide
  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  // Function to go to a specific slide
  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="relative w-10/12 pt-4 pb-4 mx-auto">
      <div className="relative h-52 overflow-hidden rounded-lg md:h-96">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-transform duration-700 ease-in-out ${
              activeIndex === index ? 'translate-x-0' : 'translate-x-full'
            }`}
            style={{ transform: `translateX(${(index - activeIndex) * 100}%)` }}
          >
            <img
              src={slide}
              className="block w-full h-full object-cover"
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center space-x-3 mt-4">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${activeIndex === index ? 'bg-white' : 'bg-gray-400'}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>

      <button
        type="button"
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-transparent p-2 focus:outline-none"
        onClick={prevSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <FaChevronLeft className="text-white" />
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-transparent p-2 focus:outline-none"
        onClick={nextSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <FaChevronRight className="text-white" />
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default Offer;
