import React, { useContext, useState, useEffect } from 'react';
import ProductList from '../Components/ProductList';
import { CategoryContext } from '../context/CategoryContext';

const ProductPage = () => {
  const [price, setPrice] = useState('');
  const [gender, setGender] = useState('');
  const { category, setCategory } = useContext(CategoryContext);

  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleGenderChange = (e) => setGender(e.target.value);
  
  // Ensure the category state is updated when context changes
  useEffect(() => {
    setCategory(category);
  }, [category, setCategory]);

  return (
    <div id='/products' className='py-5'>
      <div className="container mb-4 w-3/4 mx-auto border-black border-2 p-1 rounded-md">
        <div className="grid grid-cols-3 justify-evenly divide-x">
          {/* Price Filter */}
          <div className="bg-gray-100 justify-center items-center text-center">
            <div className='my-auto'>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price:</label>
              <select id="price" name="price" value={price} onChange={handlePriceChange}>
                <option value="">Any</option>
                <option value="0-25">₹0 - ₹25</option>
                <option value="25-50">₹25 - ₹50</option>
                <option value="50-100">₹50 - ₹100</option>
                <option value="100+">₹100+</option>
              </select>
            </div>
          </div>

          {/* Category Filter */}
          <div className="bg-gray-100 text-center">
            <div className="my-auto">
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category:</label>
              <select id="category" name="category" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">All</option>
                <option value="electronics">Electronics</option>
                <option value="clothing">Clothing</option>
                <option value="furniture">Furniture</option>
                <option value="books">Books</option>
                {/* Add more categories here if needed */}
              </select>
            </div>
          </div>

          {/* Gender Filter */}
          <div className="bg-gray-100 text-center">
            <div className="my-auto">
              <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender:</label>
              <select id="gender" name="gender" value={gender} onChange={handleGenderChange}>
                <option value="">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unisex">Unisex</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ProductList price={price} gender={gender} category={category} />
    </div>
  );
};

export default ProductPage;
