// src/context/CategoryContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const [category, setCategory] = useState('all');

  // Initialize category to 'all' when the component mounts
  useEffect(() => {
    setCategory('all');
  }, []);

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};
